<template>
  <div>
    <h2>Herramientas de administradores</h2>
    <div class="box vs-con-loading__container" v-if="ready">
      <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
        <vs-col vs-justify="left" vs-align="center" vs-w="4">
          Eliminar usuarios &nbsp;
          <vs-switch v-model="params.removeAccess" @change="saveParams" /><br><br>
        </vs-col>
        <vs-col vs-justify="left" vs-align="center" vs-w="4">
          Eliminar clientes &nbsp;
          <vs-switch v-model="params.removeCustomers" @change="saveParams" /><br><br>
        </vs-col>
        <vs-col vs-justify="left" vs-align="center" vs-w="4">
          Eliminar empresas &nbsp;
          <vs-switch v-model="params.removeClients" @change="saveParams" /><br><br>
        </vs-col>
        <vs-col vs-justify="left" vs-align="center" vs-w="4">
          <vs-button @click="btnLoader($event, saveEmulators)">Respaldar Emu</vs-button>&nbsp;
        </vs-col>
      </vs-row>
      <br />
    </div>
  </div>
</template>

<script>
import btnLoader from './btnLoader'
import { httpsCallable, functions, doc, setDoc, collection, db, onSnapshot } from '../firebase'

export default {
  data() {
    return {
      params: {},
      ready: false,
    }
  },
  methods: {
    async saveEmulators(stopLoader) {
      await httpsCallable(functions, 'utils')({ tool: 'exportEmulators' }).then(res => {
        console.log('res', res)
      }).catch(err => {
        console.log('err', err)
        console.log(`No pude ejecutar:`, err);
      })
      stopLoader(true)
    },
    async saveParams() {
      if (!this.ready) return;
      await setDoc(doc(collection(db, 'config'), 'params'), this.params);
    },
  },
  mounted() {
    this.btnLoader = btnLoader(this)
   
   const paramsRef = doc(collection(db, 'config'), 'params');
    onSnapshot(paramsRef, (snapshot) => {
      if (snapshot.data()) this.params = snapshot.data();
      this.ready = true;
    });


  }
};
</script>

<style >
 .switch {
   margin-bottom: 50px !important;
 }
</style>